<template>
  <v-container class="pa-0" fluid>
    <page-title :heading="$t('accounting.lang_canteenBilling')"
                :subheading="$t('accounting.lang_canteenBilling')"
                :icon="icon"
    ></page-title>
    <div class="app-main__inner">
      <CanteenBillingComponent/>
    </div>
  </v-container>
</template>

<script>
import PageTitle from "../../../Layout/Components/PageTitle";
import CanteenBillingComponent from "@/components/accounting/canteenbilling/CanteenBillingComponent";

export default {
  name: "CanteenBilling",
  components: {
    CanteenBillingComponent,
    PageTitle,
  },

  data: () => ({
    icon: 'pe-7s-note2 icon-gradient bg-tempting-azure',
  })
}
</script>
