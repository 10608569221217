<template>
  <v-card elevation="0" >
    <v-card-title>
      {{ $t('accounting.lang_viewBilling') }}
    </v-card-title>
    <v-divider class="ma-0"/>
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="6">
          <BaseDateInput v-model="startDate" :disabled="loading"
                        :label="$t('accounting.lang_dailyReportStart')"
                        :loading="loading"
                        :rules="[v => !!v]" dense outlined hide-details
                        type="date"/>
        </v-col>
        <v-col cols="12" sm="6">
          <BaseDateInput v-model="endDate" :disabled="loading"
                        :label="$t('accounting.lang_dailyReportEnd')"
                        :loading="loading"
                        :rules="[v => !!v]" dense outlined hide-details
                        type="date"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-select :label="$t('accounting.lang_classes')" :items="allClasses" outlined dense multiple v-model="classes"
                    :loading="loading||loadingClasses" item-text="name" item-value="uuid" hide-details @change="getAllGroups">

          </v-select>
        </v-col>
        <v-col cols="6">
          <v-select :disabled="!(classes && classes.length<=1 && classes.length>0)" :label="$t('generic.lang_groups')" :items="allGroups"
                    outlined dense multiple v-model="groups" item-text="name" item-value="name" :loading="loading||loadingGroups"  hide-details>

          </v-select>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text class="pa-0 pt-2">
      <Datatable ref="generatedInvoices" :api-endpoint="ENDPOINTS.DATATABLES.ACCOUNTING.SEARCHINVOICES"
                 :datatable-headers="datatableHeaders"
                 no-excel-export
                 show-display-buttons
                 pdf-f-ile-name="invoices"
                 show-pdf
      />
    </v-card-text>
  </v-card>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import Datatable from "@/components/datatable/Datatable";
import DatePicker from "@/components/common/datepicker";
import BaseDateInput from "@/components/common/BaseDateInput.vue";

export default {
  name: "GeneratedInvoicesComponent",
  components:{Datatable, DatePicker, BaseDateInput},
  data(){
    return{
      ENDPOINTS,
      loading:false,
      loadingClasses:false,
      loadingGroups:false,
      startDate:null,
      endDate:null,
      allClasses:[],
      allGroups:[],
      classes:[],
      groups:[],
    };
  },
  computed: {
    datatableHeaders() {
      return [
        {text: this.$t('generic.lang_id'), value: "id", sort: "desc"},
        {text: this.$t('generic.lang_time') + " / " + this.$t('generic.lang_date'), value: "date"},
        {text: this.$t('accounting.lang_cartID'), value: "cartID"},
        {text: this.$t('generic.lang_customer'), value: "cust"},
        {text: this.$t('erp.lang_ware_total'), value: "total"},
        {text: this.$t('accounting.lang_paymenthod'), value: "paymentType"},
        {text: this.$t('generic.lang_class'), value: "class"},
        {text: this.$t('generic.lang_group'), value: "group"},
      ];
    },
  },
  methods:{
    getAllClasses(){
      this.loadingClasses=true;
      this.axios.post(ENDPOINTS.KANTINE.SETTINGS.CLASSES.GET).then(res=>{
        this.allClasses=res.data.classes;
      }).catch(err=>{
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        })
      }).finally(()=>{
        this.loadingClasses=false;
      });
    },
    getAllGroups(){
      if(!(this.classes.length>0 && this.classes.length<=1)){
        return ;
      }
      this.loadingGroups=true;
      this.axios.post(ENDPOINTS.KANTINE.SETTINGS.GROUPS.GET,{
        classUUID: this.classes[0]
      }).then(res=>{
        this.allGroups=res.data.groups;
      }).catch(err=>{
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        })
      }).finally(()=>{
        this.loadingGroups=false;
      });
    },
  },
  mounted() {
    this.getAllClasses()
  }
}
</script>

<style scoped>

</style>