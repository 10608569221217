<template>
  <v-container fluid>
    <v-row>
      <v-col class="pb-0" cols="12" md="3" sm="12">
        <div role="tablist">
          <b-card :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="mb-1" no-body>
            <b-card-header class="p-0" header-tag="header" role="tab">
              <v-list-item @click="globalSettings =! globalSettings" class="ma-0"
                           v-b-toggle.global-settings>
                <v-list-item-title :class="this.globalSettings? 'primary--text text-wrap' : 'text-wrap'">
                  {{ $t('accounting.lang_canteenBilling') }}
                </v-list-item-title>
                <v-list-item-action>
                  <v-icon v-if="!this.globalSettings">keyboard_arrow_down</v-icon>
                  <v-icon v-if="this.globalSettings">keyboard_arrow_up</v-icon>
                </v-list-item-action>
              </v-list-item>
            </b-card-header>
            <b-collapse :visible="this.globalSettings" accordion="global-settings" id="global-settings"
                        role="tabpanel">
              <b-card-body class="pa-0">
                <b-list-group flush>
                  <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'invoiceGenerator'? 'primary--text' : '']"
                                     @click="handleClick('invoiceGenerator')"
                                     class="items">
                    {{ $t('accounting.lang_startBilling') }}
                  </b-list-group-item>
                  <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'generatedInvoices'? 'primary--text' : '']"
                                     @click="handleClick('generatedInvoices')"
                                     class="items">
                    {{ $t('accounting.lang_viewBilling') }}
                  </b-list-group-item>
                </b-list-group>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </v-col>

      <v-col cols="12" md="9" sm="12">

        <div v-if="currentTab === 'invoiceGenerator'">
          <InvoiceGeneratorComponent/>
        </div>
        <div v-if="currentTab === 'generatedInvoices'">
          <GeneratedInvoicesComponent/>
        </div>

      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import mixin from "@/mixins/KeyboardMixIns";
import Tabs from 'vue-tabs-with-active-line';
import InvoiceGeneratorComponent from "@/components/accounting/canteenbilling/invoicegenerator/InvoiceGeneratorComponent";
import GeneratedInvoicesComponent
  from "@/components/accounting/canteenbilling/invoicegenerator/GeneratedInvoicesComponent";

export default {
  name: "CanteenBillingComponent",
  components: {
    GeneratedInvoicesComponent,
    InvoiceGeneratorComponent,
    Tabs
  },
  mixins: [mixin],
  data() {
    return {
      currentTab: 'invoiceGenerator',
      posName: null,
      taxID: null,
      streetNo: null,
      zip: null,
      city: null,
      country: null,
      globalSettings: true,
      // ----------------------------------

    }
  },
  methods: {
    handleClick(newTab) {
      this.currentTab = newTab;
    },

  },
  computed: {
    tabs: function () {
      return [
        {
          title: this.$t('generic.lang_basicData'),
          value: 'generalInfo',
        },
        {
          title: 'Logos',
          value: 'logos',
        }
      ];
    }
  },
  mounted() {
    //GET DATA
    //this.getData();
  },
}
</script>

<style scoped>
.tabs > .tabs__item, .tabs {
  width: auto !important;
  z-index: 1 !important;
}

.tabs__item_active, .tabs__active-line, .shadow-tabs .tab-item-line {
  padding-top: 20px !important;
}

.tabs__item {
  z-index: 1 !important;
}

.tabs__item:hover {
  border: none;
  outline: none;
}

.tabs__item:focus {
  border: none;
  outline: none;
}

.items {
  cursor: pointer !important;
}

</style>
