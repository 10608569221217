<template>
  <v-card :loading="loading">
    <v-card-title>
      {{ $t('accounting.lang_startBilling') }}
    </v-card-title>
    <v-divider class="ma-0"/>
          <v-card-text class="px-2 pt-2">
              <v-row>
                <v-col cols="8">
                  <date-picker v-model="date" type="month" hide-details dense clearable/>
                </v-col>
                <v-col cols="4" align="left" align-self="center">
                  <v-btn class="no-text-transform ma-0" color="primary" :loading="loading" :disabled="loading||!date" block ><span class="text-wrap">{{ $t('accounting.lang_billMonth') }}</span></v-btn>
                </v-col>
              </v-row>
          </v-card-text>
  </v-card>
</template>

<script>
import DatePicker from '@/components/common/datepicker'
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";

export default {
  name: "InvoiceGeneratorComponent",
  components:{DatePicker},
  data(){
    return{
      ENDPOINTS,
      loading:false,
      date:null,
    };
  },
  methods:{

  },
  mounted() {
  }
}
</script>

<style scoped>

</style>